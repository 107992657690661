<template src="./landing-page.html"></template>

<script>
/* eslint-disable */

import mainSlider from "../main-slider/main-slider";
import mobileSlider from "../in-mob-slider/in-mob-slider";
import ScrollMagic from "scrollmagic";
import { addStats } from "../../services/apiList";
import VueTinySlider from "vue-tiny-slider";

export default {
  name: "landingPage",
  components: {
    "app-main-slider": mainSlider,
    "in-mob-slider": mobileSlider,
    "tiny-slider": VueTinySlider,
  },
  data() {
    return {
      pricing: {
        singleCourse: true,
        anyThree: false,
        allSix: false,
      },
      heart: {
        white: true,
        pink: false,
      },
      quizzes: {
        leaderboard: false,
      },
      placeHolder: "",
      velocityCount: "Group-blank",
      instructors: {
        yaruq: false,
        talal: true,
        sehar: false,
      },
      retentionComprehensive: {
        retenstion: true,
        retenstionText: true,
        comprehensive: false,
        comprehensiveText: false,
        qu: true,
        difficulty: false,
        board: false,
        strength: false,
        peers: false,
      },
      barPercentage: 0,
      courses: [
        {
          id: 520921879,
          name: "Chemistry Part 1",
          iframe:
            "https://player.vimeo.com/video/520921879?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        {
          id: 520922234,
          name: "Chemistry Part 2",
          iframe:
            "https://player.vimeo.com/video/520922234?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        {
          id: 520921495,
          name: "Biology Part 1",
          iframe:
            "https://player.vimeo.com/video/520921495?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        {
          id: 521892373,
          name: "Biology Part 2",
          iframe:
            "https://player.vimeo.com/video/521892373?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        {
          id: 520922071,
          name: "Mathematics Part 1",
          iframe:
            "https://player.vimeo.com/video/520922071?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        {
          id: 521901506,
          name: "Mathematics Part 2",
          iframe:
            "https://player.vimeo.com/video/521901506?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        {
          id: 520921725,
          name: "Physics Part 1",
          iframe:
            "https://player.vimeo.com/video/520921725?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        {
          id: 521892452,
          name: "Physics Part 2",
          iframe:
            "https://player.vimeo.com/video/521892452?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        {
          id: 520387483,
          name: "English Part 1",
          iframe:
            "https://player.vimeo.com/video/520387483?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        {
          id: 520387674,
          name: "English Part 2",
          iframe:
            "https://player.vimeo.com/video/520387674?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        {
          id: 520387861,
          name: "Accounting (I.Com)",
          iframe:
            "https://player.vimeo.com/video/520387861?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        {
          id: 521296511,
          name: "Computer Science Part 1",
          iframe:
            "https://player.vimeo.com/video/521296511?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        {
          id: 521297793,
          name: "Urdu Part 1",
          iframe:
            "https://player.vimeo.com/video/521297793?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        {
          id: 520922413,
          name: "Islamic Studies",
          iframe:
            "https://player.vimeo.com/video/520922413?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
        },
        {
          id: 521297678,
          name: "Pakistan Studies",
          iframe:
            "https://player.vimeo.com/video/521297678?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
        },
      ],
      activeCourse: "",
      clickedVideo: {
        id: "",
      },
      videoPlayed: false,
      screen: {
        mobile: false,
        web: true,
      },
      stats: {
        websiteName: "fsc-landing-app",
        websiteEvent: "N/A",
        userEmail: "",
        userContactNo: "N/A",
      },
      submitted: false,
    };
  },
  methods: {
    filter(type) {
      Object.keys(this.pricing).forEach((element) => {
        if (element == type) {
          this.pricing[element] = true;
        } else {
          this.pricing[element] = false;
        }
      });
    },
    retentionFilter(success, lose) {
      success.forEach((element) => {
        this.retentionComprehensive[element] = true;
      });
      lose.forEach((element) => {
        this.retentionComprehensive[element] = false;
      });
    },
    hoverFilter(type) {
      Object.keys(this.instructors).forEach((element) => {
        if (element == type) {
          this.instructors[element] = true;
        } else {
          this.instructors[element] = false;
        }
      });
    },
    async addStats(statName) {
      this.stats.websiteEvent = statName;
      if (statName == "submit-phone-number") {
        await addStats(this.stats);
        this.stats.userContactNo = "";
        this.submitted = true;
        setTimeout(() => {
          this.submitted = false;
        }, 3000);
      } else {
        await addStats(this.stats);
        this.stats.userContactNo = "";
      }
    },

    hitIt() {
      this.heart.white = false;
      this.heart.pink = true;
      this.stats.websiteEvent = "love-click";
      addStats(this.stats);
      setTimeout(() => {
        document.getElementById("x-men").scrollIntoView();
      }, 1200);
    },
    calculatePercentage(current, end) {
      let a = (current / end) * 100;
      return a.toFixed();
    },
    playVideo(item) {
      this.clickedVideo = this.activeCourse = item;
      this.videoPlayed = true;
      this.stats.websiteEvent = "course-click-" + this.clickedVideo.name;
      addStats(this.stats);
      let iframe = document.querySelector("iframe");
      iframe.onload = () => {
        let player = $f(iframe);
        player.api("play");
      };
    },
    pauseVideo() {
      let iframe = document.querySelector("iframe");
      let player = $f(iframe);
      player.api("pause");
    },
    scrollDirection() {
      var scrollableElement = document.body;

      scrollableElement.addEventListener("wheel", checkScrollDirection);

      function checkScrollDirection(event) {
        if (checkScrollDirectionIsUp(event)) {
          console.log("up");
        } else {
          console.log("down");
        }
      }

      function checkScrollDirectionIsUp(event) {
        if (event.wheelDelta) {
          return event.wheelDelta > 0;
        }
        return event.deltaY < 0;
      }
    },
    youDeserve() {
      const youDeserve = document.getElementById("section-two");
      document.addEventListener("scroll", (e) => {
        let positionOfScrollForStart = window.scrollY - youDeserve.offsetTop;
        let percentage = this.calculatePercentage(
          positionOfScrollForStart,
          youDeserve.offsetHeight
        );
        if (
          (percentage > 0 && percentage < 10) ||
          (percentage > 90 && percentage < 100)
        ) {
          this.pauseVideo();
        }
      });
    },
    affordableQuality() {
      const youDeserve = document.querySelector(".affordable-quality");
      let controller = new ScrollMagic.Controller();

      let scene = new ScrollMagic.Scene({
        triggerElement: youDeserve,
        triggerHook: 0,
        duration: 1500,
      })
        .setPin(youDeserve)
        .addTo(controller);

      scene.on("update", (e) => {
        if (e.scrollPos > e.startPos && e.scrollPos < e.endPos) {
          let currentScroll = e.scrollPos - e.startPos;
          if (currentScroll < 500) {
            this.filter("singleCourse");
          } else if (currentScroll < 1000) {
            this.filter("anyThree");
          } else if (currentScroll < 1500) {
            this.filter("allSix");
          }
        }
      });
    },
    introSection() {
      const intro = document.querySelector(".intro_");
      const content = intro.querySelector(".video-lectures");
      const TBT = intro.querySelector(".taught-by-teacher");
      const instructors = intro.querySelector(".instructors");
      const video = intro.querySelector("video");
      let controller = new ScrollMagic.Controller();
      let scene = new ScrollMagic.Scene({
        triggerElement: intro,
        triggerHook: 0,
        duration: 1500,
      })
        .setPin(intro)
        .addTo(controller);
      scene.on("update", (e) => {
        if (e.scrollPos > e.startPos && e.scrollPos < e.endPos) {
          let total = e.endPos - e.startPos;
          let currentScroll = e.scrollPos - e.startPos;
          let percentage = this.calculatePercentage(currentScroll, total);
          if (percentage < 65) {
            video.style.transform =
              "perspective(1200px) translateZ(0px) translateX(0px) translateY(0px) rotateY(" +
              (percentage - 35 < 0 ? 0 : percentage - 35) +
              "deg)";
          }
          if (percentage) {
            video.style.width =
              (110 - percentage < 35 ? 35 : 110 - percentage) + "%";
            content.style.top = 80 - percentage + "%";
            instructors.style.left = -65 + (percentage - 25) + "%";
            TBT.style.opacity = (percentage - 35) / 100;
            TBT.style.right = percentage / 10 + "%";
          }
          if (percentage < 10) {
            video.style.transform =
              "perspective(1200px) translateZ(0px) translateX(0px) translateY(0px) rotateY(0deg)";
            video.style.width = "100%";
            TBT.style.opacity = 0;
          }
        }
      });
    },
    sectionAAA() {
      const AAA = document.querySelector(".section-AAA");
      const contentH = AAA.querySelector(".AAA-child");
      let controller = new ScrollMagic.Controller();

      let scene = new ScrollMagic.Scene({
        triggerElement: AAA,
        triggerHook: 0,
        duration: 1000,
      })
        .setPin(AAA)
        .addTo(controller);

      scene.on("update", (e) => {
        if (e.scrollPos > e.startPos && e.scrollPos < e.endPos) {
          let total = e.endPos - e.startPos;
          let currentScroll = e.scrollPos - e.startPos;
          let percentage = this.calculatePercentage(currentScroll, total);
          if (percentage < 50) {
            contentH.style.transform = "translateY(0px)";
          } else if (percentage > 50) {
            contentH.style.transform = "translateY(-200px)";
          }
        }
      });
    },
    sectionComprehensive() {
      const comprehensive = document.querySelector(".comprehensive");
      const quizzesWires = comprehensive.querySelector(".quizzes-wires");
      const contentH = comprehensive.querySelector(".AAA-child");
      let controller = new ScrollMagic.Controller();

      let scene = new ScrollMagic.Scene({
        triggerElement: comprehensive,
        triggerHook: 0,
        duration: 1000,
      })
        .setPin(comprehensive)
        .addTo(controller);

      scene.on("update", (e) => {
        if (e.scrollPos > e.startPos && e.scrollPos < e.endPos) {
          let total = e.endPos - e.startPos;
          let currentScroll = e.scrollPos - e.startPos;
          let percentage = this.calculatePercentage(currentScroll, total);
          if (percentage < 50) {
            this.quizzes.leaderboard = false;
            let margin_ = 150;
            let scale_ = 1.5;
            scale_ = scale_ - percentage * 0.01;
            margin_ = margin_ - percentage * 3;
            quizzesWires.style.margin = margin_ + "px";
            quizzesWires.style.transform = "scale(" + scale_ + ")";
            contentH.style.transform = "translateY(0px)";
          } else {
            this.quizzes.leaderboard = true;
            quizzesWires.style.margin = "0px";
            quizzesWires.style.transform = "scale(1)";
            contentH.style.transform = "translateY(-200px)";
          }
        }
      });
    },
    comprehensiveQuizMobile() {
      const yourRetention = document.querySelector(".retention-comprehensive");
      let controller = new ScrollMagic.Controller();

      let scene = new ScrollMagic.Scene({
        triggerElement: yourRetention,
        triggerHook: 0,
        duration: 1400,
      })
        .setPin(yourRetention)
        .addTo(controller);

      scene.on("update", (e) => {
        if (e.scrollPos > e.startPos && e.scrollPos < e.endPos) {
          let total = e.endPos - e.startPos;
          let currentScroll = e.scrollPos - e.startPos;
          let percentage = this.calculatePercentage(currentScroll, total);
          if (percentage < 20) {
            this.retentionFilter(
              ["retenstion", "retenstionText"],
              [
                "comprehensive",
                "comprehensiveText",
                "difficulty",
                "board",
                "strength",
                "peers",
              ]
            );
          }
          if (percentage > 20 && percentage < 40) {
            this.retentionFilter(
              ["comprehensive", "comprehensiveText"],
              [
                "retenstion",
                "retenstionText",
                "difficulty",
                "board",
                "strength",
                "peers",
              ]
            );
          }
          if (percentage > 40 && percentage < 60) {
            this.retentionFilter(
              ["difficulty"],
              ["retenstion", "retenstionText", "board", "strength", "peers"]
            );
          }
          if (percentage > 60 && percentage < 80) {
            this.retentionFilter(
              ["board"],
              [
                "retenstion",
                "retenstionText",
                "difficulty",
                "strength",
                "peers",
              ]
            );
          }
          if (percentage > 80 && percentage < 100) {
            this.retentionFilter(
              ["strength"],
              ["retenstion", "retenstionText", "board", "difficulty", "peers"]
            );
          }
        }
      });
    },
    singleLaptop() {
      const singleLaptop = document.querySelector(".single-laptop");
      const content = singleLaptop.querySelector(".heavy");
      const image_ = singleLaptop.querySelector("img");
      let controller = new ScrollMagic.Controller();

      let scene = new ScrollMagic.Scene({
        triggerElement: singleLaptop,
        triggerHook: 0,
        duration: 800,
      })
        .setPin(singleLaptop)
        .addTo(controller);

      scene.on("update", (e) => {
        if (e.scrollPos > e.startPos && e.scrollPos < e.endPos) {
          let total = e.endPos - e.startPos;
          let currentScroll = e.scrollPos - e.startPos;
          let percentage = this.calculatePercentage(currentScroll, total);
          if (percentage > 20) {
            let scale_ = 0.5;
            let opacity = 1;
            scale_ = scale_ + percentage * 0.01;
            opacity = opacity - percentage / 100;
            content.style.transform = "scale(" + scale_ + ")";
            image_.style.opacity = opacity < 0.2 ? 0.2 : opacity;
          }
        }
      });
    },
    velocityPortal() {
      const velocity = document.querySelector(".velocity-portal");
      const aTeam = velocity.querySelector(".a");
      const bTeam = velocity.querySelector(".b");
      // const video = bTeam.querySelector("video");
      const childX = aTeam.querySelector(".child-x");
      let controller = new ScrollMagic.Controller();

      let scene = new ScrollMagic.Scene({
        triggerElement: velocity,
        triggerHook: 0,
        duration: 2000,
      })
        .setPin(velocity)
        .addTo(controller);
      scene.on("update", (e) => {
        if (e.scrollPos > e.startPos && e.scrollPos < e.endPos) {
          let videoTime = 0;

          let total = e.endPos - e.startPos;
          let currentScroll = e.scrollPos - e.startPos;
          let percentage = this.calculatePercentage(currentScroll, total);
          if (this.screen.web) {
            let translateX_ = 100;
            translateX_ = percentage < 10 ? translateX_ - percentage * 10 : 0;
            translateX_ = translateX_ > 89 ? 100 : translateX_;
            aTeam.style.transform = "translateX(-" + translateX_ + "%)";
            bTeam.style.transform = "translateX(" + translateX_ + "%)";
          }
          if (percentage < 10) {
            this.velocityCount = this.screen.mobile
              ? "Group-5119"
              : "LMS-Courses-0";
          }
          if (percentage > 10) {
            videoTime = (currentScroll / 100).toFixed();
            // video.currentTime = videoTime - 1;
            if (videoTime < 5) {
              this.velocityCount = this.screen.mobile
                ? "Group-5119"
                : "LMS-Courses-0";
              childX.style.transform = "translateY(0px)";
            }
            if (videoTime > 5) {
              this.velocityCount = this.screen.mobile
                ? "Group-5120"
                : "LMS-Courses-0";
              childX.style.transform = "translateY(-200px)";
            }
            if (videoTime > 10) {
              this.velocityCount = this.screen.mobile
                ? "Group-5122"
                : "LMS-Courses-2";
              childX.style.transform = "translateY(-400px)";
            }
            if (videoTime > 15) {
              this.velocityCount = this.screen.mobile
                ? "Group-5125"
                : "LMS-Courses-4";
              childX.style.transform = "translateY(-600px)";
            }
          }
        }
      });
    },
  },
  mounted() {
    document.addEventListener(
      "click",
      (event) => {
        this.stats.websiteEvent = "Class: " + event.target.className;
        addStats(this.stats);
      },
      false
    );
    this.stats.websiteEvent = "home-page-hit";
    addStats(this.stats);
    if (window.innerWidth < 800) {
      this.screen.mobile = true;
      this.screen.web = false;
      this.placeHolder = "Call Me to Guide";
      this.comprehensiveQuizMobile();
    } else {
      this.placeHolder = "Phone Number";
      this.introSection();
      this.sectionAAA();
      this.sectionComprehensive();
    }
    this.activeCourse = this.courses[0];
    this.youDeserve();
    this.singleLaptop();
    this.velocityPortal();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./landing-page.css" scoped></style>
